import React, {Component} from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import HowToBanner from "../components/HowTo/HowToBanner";
import HowToContent from "../components/HowTo/HowToContent";
import Breadcrumb from "../components/Breadcrumb";
import HowToOther from '../components/HowTo/HowToOther';

class Product extends Component {
    render() {
      // console.log(this.props);
      
      return (
        <Layout
          meta={{
            ...this.props.data.wpProduct.Meta,
            canonical: this.props.data.wpProduct.uri
          }}
          path={this.props.pageContext.pagePath}
          cta={this.props.data.wpProduct.PageCTA}
          themeOptions={this.props.data.wp.themeOptions}
        >
          <Breadcrumb
            type="how-to"
            current={{
              title: this.props.data.wpProduct.title
            }}
          />
          <HowToBanner
            name={this.props.data.wpProduct.title}
            modified={this.props.data.wpProduct.modified}
            intro={this.props.data.wpProduct.product.introduction}
            images={this.props.data.wpProduct.product.productGallery}
            pageContext={this.props.pageContext}
          />
          <HowToContent
            product={this.props.data.wpProduct.product}
          />
          <HowToOther />
        </Layout>
      )
    }
}

export const query = graphql`
  query ($id: String) {
    wpProduct(id: {eq: $id}) {
      id
      slug
      title
      uri
      modified(formatString: "DD MMMM YYYY")
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage{
          uri
        }        
      }
      product {
        productGallery {
          id
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        introduction
        installDescription
        installVideo
        installGuide
        installVideoImage {
            localFile {
                publicURL
                childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        installGuideImage {
            localFile {
                publicURL
                childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        fixDescription
        fixVideo
        fixGuide
        fixVideoImage {
            localFile {
                publicURL
                childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        fixGuideImage {
            localFile {
                publicURL
                childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
      },
    },
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default Product
