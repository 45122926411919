export default theme => ({
    howToOther: {
        position: 'relative',
        padding: '80px 0',
        '&::before': {
            backgroundColor: theme.palette.primary.main + '14',
            position: 'absolute',
            content: '""',
            top: 0,
            bottom: 0,
            width: '100vw',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: -1,
        },
        '& h4': {
            marginBottom: 50,
        },
        '& .guide-listing': {
            display: 'flex',
            width: 'calc(100% + 24px)',
            margin: '0 -12px',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            '& a': {
                flex: '0 0 calc(50% - 24px)',
                maxWidth: 'calc(50% - 24px)',
                backgroundColor: theme.palette.primary.main + '14',
                padding: '40px 32px 24px',
                fontWeight: 'bold',
                textDecoration: 'none',
                fontSize: 18,
                letterSpacing: '0.008em',
                lineHeight: 2,
                textAlign: 'center',
                color: theme.palette.primary.main,
                marginBottom: 24,
                display: 'block',
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 calc(33.333% - 24px)',
                    maxWidth: 'calc(33.333% - 24px)',
                },
                '& .gatsby-image-wrapper': {
                    width: 72,
                    height: 72,
                    borderRadius: '50%',
                    overflow: 'hidden',
                    margin: '0 auto 24px',
                    display: 'block',
                },
                '& span': {
                    display: 'block',
                }
            }
        }
    },
});