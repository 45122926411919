import backArrow from "../../../images/button-arrow.png";

export default theme => ({
    howtoBanner: {
        position: 'relative',
        marginBottom: 36,
        [theme.breakpoints.up('md')]: {
            marginBottom: 72,
        },
        '& .banner-wrap': {
            paddingTop: 50,
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                width: '75%',
                padding: '50px 12px 0',
                margin: '0 auto',
            },
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                bottom: 60,
                width: '100vw',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: theme.palette.primary.main + '26',
                display: 'block',
                zIndex: -1,
            },
            '& .content': {
                '& .modified': {
                    fontSize: 10,
                    fontFamily: theme.headerFont,
                    textTransform: 'uppercase',
                    letterSpacing: '0.152em',
                    lineHeight: 1.6,
                },
                '& h1': {
                    fontSize: 28,
                    lineHeight: 2.28,
                    fontWeight: '700',
                    fontFamily: theme.headerFont,
                    marginTop: 18,
                    [theme.breakpoints.up('md')]: {
                        fontSize: 48,
                        lineHeight: 1.33,
                    }
                },
                '& p': {
                    letterSpacing: '0.032em',
                    lineHeight: 1.5,
                    color: theme.palette.primary.main,
                    marginTop: 0,
                    marginBottom: 32,
                    fontSize: 16,
                }
            },
            '& .image': {
                backgroundColor: 'white',
                boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
                display: 'block',
                '& .gatsby-image-wrapper': {
                    maxWidth: 458,
                    margin: '12px auto !important',
                    display: 'block',
                }
            },
        },
        '& .extras': {
            paddingTop: 24,
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                paddingTop: 0,
                marginTop: -36,
            },
            '& .go-back': {
                '& button': {
                    fontSize: 18,
                    padding: 0,
                    border: 'none',
                    backgroundColor: 'transparent',
                    color: theme.palette.primary.main,
                    letterSpacing: '0.008em',
                    textTransform: 'uppercase',
                    fontFamily: theme.bodyFont,
                    outline: 'none',
                    '&::before': {
                        content: '""',
                        backgroundImage: 'url('+backArrow+')',
                        width: 17,
                        height: 20,
                        marginRight: 10,
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        transform: 'rotate(180deg)',
                    }
                }       
            },
            '& .share': {
                position: 'absolute',
                top: 10,
                right: 0,
                [theme.breakpoints.up('md')]: {
                    top: 0,
                }
            }
        }
    },
});