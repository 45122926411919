import linkArrow from '../../../images/button-arrow-white.png';
import close from '../../../images/close-icon.png';
import videoArrow from '../../../images/video-arrow.png';
import guideDownload from '../../../images/pdf-download.png';

export default theme => ({
    howtoContent: {
        marginBottom: 36,
        [theme.breakpoints.up('md')]: {
            width: '75%',
            padding: '0 12px 36px',
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            width: '62.5%',
        },
        '& .links': {
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                width: 'calc(100% + 24px)',
                margin: '0 -12px',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-evenly',
            },
            '& .image-link': {
                display: 'block',
                width: '100%',
                paddingBottom: '100%',
                padding: 20,
                boxShadow: '0 6px 10px rgba(0,0,0,0.08)',
                color: 'white',
                backgroundColor: theme.palette.secondary.dark,
                textDecoration: 'none !important',
                position: 'relative',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                overflow: 'hidden',
                marginBottom: 32,
                [theme.breakpoints.up('sm')]: {
                    flex: '0 0 calc(50% - 24px)',
                    maxWidth: 'calc(50% - 24px)',
                    paddingBottom: '50%',
                    margin: '0 12px 36px',
                },
                '&:hover, &:focus': {
                    textDecoration: 'none',
                    '& svg': {
                        marginLeft: 24,
                    }
                },
                '&::after': {
                    width: 70,
                    height: 70,
                    bottom: 0,
                    position: 'absolute',
                    right: 0,
                    backgroundImage: 'url(' + linkArrow + ')',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    content: '""',
                },
                '& .text-wrap': {
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 70,
                    backgroundColor: theme.palette.primary.contrastText,
                    padding: '22px 32px',
                    '& .text': {
                        fontFamily: theme.headerFont,
                        fontSize: 18,
                        lineHeight: 1.5,
                        letterSpacing: '0.008em',
                        position: 'relative',
                        zIndex: '1',
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                    },
                },
            }
        },
        '& .con-block': {
            [theme.breakpoints.up('md')]: {
                marginTop: 50,
            },
            '& h2': {
                fontSize: 18,
                fontWeight: '500',
                lineHeight: 2.2,
                letterSpacing: '0.016em',
                [theme.breakpoints.up('md')]: {
                    fontSize: 24,
                    lineHeight: 1.66,
                }
            },
            '& p': {
                letterSpacing: '0.032em',
                lineHeight: 1.5,
                color: theme.palette.primary.main,
                marginTop: 0,
                marginBottom: 32,
                fontSize: 16,
            },
            '& .actions': {
                [theme.breakpoints.up('sm')]: {
                    display: 'flex',
                    width: 'calc(100% + 24px)',
                    margin: '0 -12px',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-evenly',
                },
                '& a, & button': {
                    borderBottomRightRadius: 6,
                    borderBottomLeftRadius: 6,
                    boxShadow: '0 4px 20px rgba(0,0,0,0.5)',
                    padding: 0,
                    backgroundColor: 'transparent',
                    display: 'block',
                    width: '100%',
                    marginBottom: 36,
                    border: 'none',
                    outline: 'none',
                    textAlign: 'left',
                    textDecoration: 'none',
                    fontFamily: theme.headerFont,
                    overflow: 'hidden',
                    [theme.breakpoints.up('sm')]: {
                        flex: '0 0 calc(50% - 24px)',
                        maxWidth: 'calc(50% - 24px)',
                        margin: '0 12px 36px',
                    },
                    '& span': {
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.secondary.contrastText,
                        fontSize: 18,
                        letterSpacing: '0.016em',
                        padding: '18px 50px 18px 24px',
                        lineHeight: 1.5,
                        fontWeight: 500,
                        display: 'block',
                        backgroundPosition: 'center right 24px',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 22,
                        [theme.breakpoints.up('md')]: {
                            padding: '40px 100px 40px 40px',
                            fontSize: 27,
                            backgroundPosition: 'center right 40px',
                            backgroundSize: 54,
                        }
                    },
                    '&.video': {

                        '& span': {
                            backgroundImage: 'url(' + videoArrow + ')',
                        }
                    },
                    '&.guide': {

                        '& span': {
                            backgroundImage: 'url(' + guideDownload + ')',
                        }
                    }
                }
            }
        },
        '& .video-overlay': {
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 100,
            padding: 32,
            display: 'none',
            backgroundColor: theme.palette.primary.main + '80',
            '&[open]': {
                display: 'block',
            },
            [theme.breakpoints.up('md')]: {
                padding: 80,
            },
            '& .content': {
                maxWidth: 1000,
                margin: '0 auto',
                position: 'relative',
                '& button': {
                    backgroundColor: theme.palette.primary.main,
                    width: 54,
                    height: 54,
                    borderRadius: '50%',
                    backgroundImage: 'url(' + close + ')',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    fontSize: 0,
                    position: 'absolute',
                    top: -16,
                    right: -16,
                    border: 'none',
                    zIndex: 10,
                    outline: 'none',
                    [theme.breakpoints.up(1160)]: {
                        top: 0,
                        right: -80,
                    }
                },
                '& .video-wrapper': {
                    paddingBottom: '56% !important',
                    width: '100%',
                    position: 'relative',
                    '& iframe': {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100% !important',
                        height: '100% !important',
                    },
                },
            },
        }
    },
    howtoContentTheme: {
        ...theme.howtoContentTheme,
    }
});