import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import ReactHtmlParser from "react-html-parser";
import styles from './styles';
import Img from "gatsby-image";
import Link from '../../Link';

const useStyles = makeStyles(theme => styles(theme));

export default function HowToContent(props) {
    const [videoInstallOpen, setVideoInstallOpen] = React.useState(false);
    const [videoFixOpen, setVideoFixOpen] = React.useState(false);
    
    const handleVideoInstallOpen = () => {
        setVideoInstallOpen(!videoInstallOpen);
    }
    
    const handleVideoFixOpen = () => {
        setVideoFixOpen(!videoFixOpen);
    }

    const classes = useStyles();

    const data = useStaticQuery(graphql`
        query installImages {
            wp {
                themeOptions {
                    ThemeOptions {
                        defaultImages {
                            defaultFixImage {
                                localFile {
                                    publicURL
                                    childImageSharp {
                                        fluid(quality: 80, maxWidth: 1660) {
                                        ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                            defaultInstallImage {
                                localFile {
                                    publicURL
                                    childImageSharp {
                                        fluid(quality: 80, maxWidth: 1660) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <div className={`howto-content ${classes.howtoContent} ${classes.howtoContentTheme}`}>
            <div className="links">
                <a href="#install" style={{backgroundImage: 'url(' + data.wp.themeOptions.ThemeOptions.defaultImages.defaultInstallImage.localFile.publicURL + ')'}} className="image-link" >
                    <div className="text-wrap">
                        <span className="text">How to install</span>
                    </div>
                </a>
                <a href="#fix" style={{backgroundImage: 'url(' + data.wp.themeOptions.ThemeOptions.defaultImages.defaultFixImage.localFile.publicURL + ')'}} className="image-link" >
                    <div className="text-wrap">
                        <span className="text">How to maintain</span>
                    </div>
                </a>
            </div>
            <div className="con-block" id="install">
                <a id="install" />
                <h2>How to install</h2>
                {ReactHtmlParser(props.product.installDescription)}
                <div className="actions">
                    {props.product.installVideo != null &&
                        <button onClick={handleVideoInstallOpen} onKeyDown={handleVideoInstallOpen} className="video">
                            {props.product.installVideoImage !== null &&
                                <Img fluid={props.product.installVideoImage.localFile.childImageSharp.fluid} />
                            }
                            <span>Watch our video</span>
                        </button>
                    }
                    {props.product.installGuide != null &&
                        <Link to={props.product.installGuide} className="guide">
                            {props.product.installGuideImage !== null &&
                                <Img fluid={props.product.installGuideImage.localFile.childImageSharp.fluid} />
                            }
                            <span>Download guide</span>
                        </Link>
                    }
                </div>
            </div>
            <div className="con-block" id="fix">
                <a id="fix" />
                <h2>How to maintain</h2>
                {ReactHtmlParser(props.product.fixDescription)}
                <div className="actions">
                    {props.product.fixVideo != null &&
                        <button onClick={handleVideoFixOpen} onKeyDown={handleVideoFixOpen} className="video">
                            {props.product.fixVideoImage !== null && 
                                <Img fluid={props.product.fixVideoImage.localFile.childImageSharp.fluid} />
                            }
                            <span>Watch our video</span>
                        </button>
                    }
                    {props.product.fixGuide != null &&
                        <Link to={props.product.fixGuide} className="guide">
                            {props.product.fixGuideImage !== null && 
                                <Img fluid={props.product.fixGuideImage.localFile.childImageSharp.fluid} />
                            }
                            <span>Download guide</span>
                        </Link>
                    }
                </div>
            </div>
            {videoInstallOpen && props.product.installVideo != null &&
                <div className="video-overlay install" open={videoInstallOpen}>
                    <div className="content">
                        <button onClick={handleVideoInstallOpen} onKeyDown={handleVideoInstallOpen}>Close</button>
                        <div className="video-wrapper">
                            <iframe title={"video-banner-"+props.product.installVideo.replace("https://www.youtube.com/watch?v=", "")}
                                src={"https://www.youtube.com/embed/"+props.product.installVideo.replace("https://www.youtube.com/watch?v=", "")+"?autoplay=0&modestbranding=1"}
                                frameBorder="0"
                                allowFullScreen
                            />
                        </div>
                    </div>
                </div>
            }
            {videoFixOpen && props.product.fixVideo != null &&
                <div className="video-overlay fix" open={videoFixOpen}>
                    <div className="content">
                        <button onClick={handleVideoFixOpen} onKeyDown={handleVideoFixOpen}>Close</button>
                        <div className="video-wrapper">
                            <iframe title={"video-banner-"+props.product.fixVideo.replace("https://www.youtube.com/watch?v=", "")}
                                src={"https://www.youtube.com/embed/"+props.product.fixVideo.replace("https://www.youtube.com/watch?v=", "")+"?autoplay=0&modestbranding=1"}
                                frameBorder="0"
                                allowFullScreen
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}