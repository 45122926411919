import React from 'react';
import { makeStyles } from '@material-ui/styles';
import ReactHtmlParser from "react-html-parser";
import styles from './styles';
import Img from "gatsby-image";
import GoBack from '../../GoBack';
import SocialShare from '../../SocialShare';

const useStyles = makeStyles(theme => styles(theme));

export default function HowToBanner(props) {
    const classes = useStyles();

    return (
        <div className={`howto-banner ${classes.howtoBanner} ${classes.howtoBannerTheme}`}>
            <div className="banner-wrap">
                <div className="content">
                    <div className="modified">Last updated {props.modified}</div>
                    <h1>{ReactHtmlParser(props.name)}</h1>
                    <p>{ReactHtmlParser(props.intro)}</p>
                </div>
                <div className="image">
                    {props.images !== null &&
                        <Img fluid={props.images[0].localFile.childImageSharp.fluid} />
                    }
                </div>
            </div>
            <div className="extras">
                <div className="go-back">
                    <GoBack />
                </div>
                <div className="share">
                    <SocialShare
                        path={props.pageContext.pagePath}
                        title={props.name}
                    />
                </div>
            </div>
        </div>
    )
}