import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import ReactHtmlParser from "react-html-parser";
import styles from './styles';
import Img from "gatsby-image";
import Link from '../../Link';

import {CoreHeadingBlock} from "../../blocks/CoreHeadingBlock";

const useStyles = makeStyles(theme => styles(theme));

export default function HowToOther(props) {
    const classes = useStyles();

    const data = useStaticQuery(graphql`
        query howToOther {
            allWpProduct(sort: {fields: modifiedGmt}, filter: {product: {hasHowtoGuide: {eq: true}}}, limit: 6) {
                nodes {
                    id
                    product {
                        hasHowtoGuide
                        productGallery {
                            id
                            localFile {
                                id
                                publicURL
                                childImageSharp {
                                    fluid(quality: 80, maxWidth: 1660) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                    modifiedGmt
                    title
                    uri
                    slug
                }
            }
        }
    `);

    return (
        <div className={`howto-content ${classes.howToOther}`}>
            <CoreHeadingBlock 
                attributes={{
                    align: "",
                    anchor: "",
                    backgroundColor: "",
                    className: "",
                    content: "Other Guides",
                    level: 4,
                    textColor: "",
                    __typename: "WpCoreHeadingBlockAttributes",
                }}
                innerBlocks={[]} 
            />
            <div className="guide-listing">
                {data.allWpProduct.nodes.map((product) => {
                    return <Link to={"/expertise/how-to/"+product.slug} key={product.id}>
                        {product.product.productGallery !== null &&
                            <Img fluid={product.product.productGallery[0].localFile.childImageSharp.fluid} alt="" className="product-image"/>
                        }
                        {ReactHtmlParser(product.title, "")}
                    </Link>
                })}
            </div>
        </div>
    )
}